import { gql } from '@apollo/client';
import { Form, SurveyResult } from './types';
import { VERSION_TYPE } from '../dataSource/types';
import { formFragment, surveyResultFragment } from './fragments';
import { baseMetadata } from '../common/fragments';
import { InviteMultipleUsersInput } from '../user/types';

export type BuildLiveFormData = { buildLiveForm: Form };

export type BuildLiveFormVars = {
  id: string;
};

export const BUILD_LIVE_FORM = gql`
  query BuildLiveForm($id: String!) {
    buildLiveForm(id: $id) {
      ...Form
    }
  }
  ${formFragment}
`;

export type GetSurveyResultsData = { getSurveyResults: SurveyResult[] };

export type GetSurveyResultsVars = {
  formId?: string;
  id?: string;
  searchText?: string;
  timezone?: string;
};

export const GET_SURVEY_RESULTS = gql`
  query GetSurveyResults(
    $formId: String
    $id: String
    $searchText: String
    $timezone: String
  ) {
    getSurveyResults(
      formId: $formId
      id: $id
      searchText: $searchText
      timezone: $timezone
    ) {
      ...SurveyResult
    }
  }
  ${surveyResultFragment}
`;

export type SearchFormsData = { searchForms: Form[] };

export type SearchFormsVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    first?: number;
  };
};

export const SEARCH_FORMS = gql`
  query SearchForms($searchInput: SearchFormsInput!) {
    searchForms(searchInput: $searchInput) {
      ...Form
    }
  }
  ${formFragment}
`;

export type GetFormVersionData = { getFormVersion: Form };

export type GetFormVersionVars = {
  versionId: string;
};

export const GET_FORM_VERSION = gql`
  query GetFormVersion($versionId: String!) {
    getFormVersion(versionId: $versionId) {
      ...Form
    }
  }
  ${formFragment}
`;

export type GetAllFormVersionsData = {
  allFormVersions: Array<Omit<Form, 'formConfig' | 'access'>>;
};

export type GetAllFormVersionsVars = {
  id: string;
};

export const GET_ALL_FORM_VERSIONS = gql`
    query GetAllFormVersions($id: String!) {
        allFormVersions(id: $id) {
            ${baseMetadata}
        }
    }
`;

export type GetLiveFormsByUserData = {
  getLiveFormsByUser: Form[];
};

export type GetLiveFormsByUserVars = {
  user?: InviteMultipleUsersInput;
};

export const GET_LIVE_FORMS_BY_USER = gql`
  query GetLiveFormsByUser($user: UserInput!) {
    getLiveFormsByUser(user: $user) {
      ...Form
    }
  }
  ${formFragment}
`;

export type GetLiveFormsByGroupData = {
  getLiveFormsByGroup: Form[];
};

export type GetLiveFormsByGroupVars = {
  groupId?: string;
};

export const GET_LIVE_FORMS_BY_GROUP = gql`
  query GetLiveFormsByGroup($groupId: String!) {
    getLiveFormsByGroup(groupId: $groupId) {
      ...Form
    }
  }
  ${formFragment}
`;

export type PermittedFormsData = { allPermittedLiveForms: Form[] };

export const PERMITTED_FORMS = gql`
  query PermittedForms {
    allPermittedLiveForms {
      id
      versionId
      formConfig {
        title
      }
    }
  }
`;
